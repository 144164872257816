const uiKit = {
    breakPoints: {
        tablet: '768px',
        laptop: '1024px',
        laptopL: '1440px',
        laptopXL: '2560px',
    },
    colors: {
        background: 'rgb(15 23 42 / 1)',
        backgroundBlur: 'rgba(15, 23, 42, 0.75)',
        text: 'rgb(226, 232, 240)',
        greyText: 'rgb(148, 163, 184)',
        experience: {
            time: 'rgb(100, 116, 139)',
            tech: 'rgba(45, 212, 191, 0.1)',
            techText: 'rgb(94, 234, 212)',
            img: 'rgba(226, 232, 240, 0.1)',
            imgHover: 'rgba(226, 232, 240, 0.3)',
        },
    },
    margins: {
        mobile: {
            sm: '24px',
            md: '48px',
        },
    },
};

export default uiKit;
