import styled from 'styled-components';
import uiKit from '../../utils/style/uiKit';
import { nanoid } from 'nanoid';

// Style
const CardContainer = styled.li`
    margin-bottom: 48px;
`;

const CardGrid = styled.div`
    display: grid;
    position: relative;
    @media screen and (min-width: ${uiKit.breakPoints.tablet}) {
        grid-template-columns: repeat(8, minmax(0, 1fr));
        column-gap: 16px;
        row-gap: 16px;
    }
`;

const CardTime = styled.p`
    font-size: 12px;
    font-weight: 600;
    color: ${uiKit.colors.experience.time};
    text-transform: uppercase;
    letter-spacing: 0.3px;
    margin-top: 4px;
    margin-bottom: 8px;
    line-height: 16px;
    @media screen and (min-width: ${uiKit.breakPoints.tablet}) {
        grid-column: span 2 / span 2;
    }
`;

const CardRight = styled.div`
    @media screen and (min-width: ${uiKit.breakPoints.tablet}) {
        grid-column: span 6 / span 6;
        &.no-img {
            grid-column: span 8 / span 8;
        }
    }
`;

const CardLinkArrow = styled.span`
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    display: inline-block;
    transform: matrix(1, 0, 0, 1, 0, 1);
`;

const CardTitle = styled.a`
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: white;
    text-decoration: none;
    &:hover ${CardLinkArrow} {
        transform: matrix(1, 0, 0, 1, 4, -4);
    }
`;

const CardDescription = styled.p`
    color: ${uiKit.colors.greyText};
    line-height: 21px;
    font-size: 14px;
    font-weight: 300;
    margin-top: 8px;
    margin-bottom: 16px;
`;

const CardTechContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
`;

const CardTechBox = styled.li`
    margin-right: 6px;
    margin-top: 8px;
`;

const CardTech = styled.div`
    background-color: ${uiKit.colors.experience.tech};
    font-size: 12px;
    line-height: 20px;
    color: ${uiKit.colors.experience.techText};
    padding: 4px 12px;
    border-radius: 9999px;
    font-weight: 500;
`;

const ImageMobile = styled.img`
    border: 2px solid ${uiKit.colors.experience.img};
    border-radius: 4px;
    @media screen and (min-width: ${uiKit.breakPoints.tablet}) {
        display: none;
    }
`;

const ImageTablet = styled.img`
    display: none;
    @media screen and (min-width: ${uiKit.breakPoints.tablet}) {
        display: block;
        grid-column: span 2 / span 2;
        max-width: 100%;
        border: 2px solid ${uiKit.colors.experience.img};
        border-radius: 4px;
    }
`;

function Card({ time, title, description, tech, img, link }) {
    return (
        <CardContainer>
            <CardGrid className="card-grid">
                <div className="card-background"></div>
                {time && <CardTime>{time}</CardTime>}
                {img && <ImageTablet src={img} />}
                <CardRight className={!time && !img && 'no-img'}>
                    <h3>
                        <CardTitle href={link} target={link && '_blank'}>
                            {title}
                            {link && (
                                <CardLinkArrow>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        viewBox="0 0 20 20"
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginLeft: '4px',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </CardLinkArrow>
                            )}
                        </CardTitle>
                    </h3>

                    <CardDescription>{description}</CardDescription>

                    {tech?.length > 0 && (
                        <CardTechContainer>
                            {tech.map((name) => (
                                <CardTechBox key={nanoid()}>
                                    <CardTech>{name}</CardTech>
                                </CardTechBox>
                            ))}
                        </CardTechContainer>
                    )}

                    {img && <ImageMobile src={img} />}
                </CardRight>
            </CardGrid>
        </CardContainer>
    );
}

export default Card;
