import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import uiKit from '../../utils/style/uiKit';
import { Experiences } from '../../utils/datas/experiences';
import { Projects } from '../../utils/datas/projects';
import Card from '../../components/Card';
import { nanoid } from 'nanoid';
import CV from '../../assets/cv_axel_capaert.pdf';

// Style
const Transition = `
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
`;

const MaxWidth = styled.div`
    max-width: 1280px;
    margin: auto;
`;

const HomeWrapper = styled.div`
    padding: 48px 24px;
    @media screen and (min-width: ${uiKit.breakPoints.tablet}) {
        padding: 80px 48px;
    }
    @media screen and (min-width: ${uiKit.breakPoints.laptop}) {
        padding: 0 96px;
        display: flex;
        justify-content: space-between;
        column-gap: 16px;
        row-gap: 16px;
    }
`;

const Gradient = styled.div`
    background: radial-gradient(
        600px at var(--x, 0px) var(--y, 0px),
        rgba(29, 78, 216, 0.15),
        transparent 80%
    );
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100vh;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
`;

const Header = styled.header`
    @media screen and (min-width: ${uiKit.breakPoints.laptop}) {
        padding: 96px 0;
        display: flex;
        flex-direction: column;
        width: 50%;
        max-height: 100vh;
        position: sticky;
        top: 0;
        box-sizing: border-box;
        justify-content: space-between;
    }
`;

const IntroSection = styled.div``;

const Title = styled.h1`
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    @media screen and (min-width: ${uiKit.breakPoints.tablet}) {
        font-size: 48px;
        line-height: 48px;
    }
`;

const SubTitle = styled.h2`
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.45px;
    font-weight: 500;
    margin-top: 12px;
    @media screen and (min-width: ${uiKit.breakPoints.tablet}) {
        font-size: 28px;
        letter-spacing: -0.5px;
    }
`;

const IntroText = styled.p`
    font-size: 16px;
    color: ${uiKit.colors.greyText};
    margin-top: 16px;
    max-width: 320px;
    line-height: 24px;
`;

const Navigation = styled.nav`
    display: none;
    @media screen and (min-width: ${uiKit.breakPoints.laptop}) {
        display: block;
    }
`;

const NavigationList = styled.ul`
    margin-top: 64px;
    width: max-content;
`;

const NavigationListItem = styled.li``;

const NavigationDecoration = styled.span`
    background-color: rgb(71 85 105 / 1);
    width: 32px;
    height: 1px;
    margin-right: 16px;
    ${Transition}
`;

const NavigationText = styled.span`
    color: rgb(100 116 139 / 1);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.2px;
    font-size: 12px;
    line-height: 16px;
`;

const NavigationLink = styled.a`
    cursor: pointer;
    padding: 12px 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    &.active ${NavigationDecoration}, &:hover ${NavigationDecoration} {
        width: 64px;
        background-color: rgb(226 232 240/1);
    }
    &.active ${NavigationText}, &:hover ${NavigationText} {
        color: rgb(226 232 240/1);
    }
`;

const Linkedin = styled.a`
    display: block;
    width: 24px;
    height: 24px;
    padding-top: 16px;
    color: ${uiKit.colors.greyText};
`;

const RightWrapper = styled.div`
    @media screen and (min-width: ${uiKit.breakPoints.laptop}) {
        width: 50%;
        padding-bottom: 96px;
    }
`;

const Main = styled.main`
    margin-top: 96px;
`;

const Section = styled.section`
    margin-bottom: 64px;
    @media screen and (min-width: ${uiKit.breakPoints.laptop}) {
        margin-bottom: 144px;
    }
`;

const SectionBanner = styled.div`
    background-color: ${uiKit.colors.backgroundBlur};
    backdrop-filter: blur(8px);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 16px;
    padding: 20px 24px;
    position: sticky;
    top: 0;
    text-transform: uppercase;
    z-index: 1;
    @media screen and (min-width: ${uiKit.breakPoints.laptop}) {
        display: none;
    }
`;

const SectionLinkArrow = styled.span`
    ${Transition}
    display: inline-block;
    transform: matrix(1, 0, 0, 1, 0, 1);
`;

const SectionLink = styled.a`
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: none;
    color: ${uiKit.colors.text};
    &:hover ${SectionLinkArrow} {
        transform: matrix(1, 0, 0, 1, 4, -4);
    }
`;

const Footer = styled.footer`
    margin-bottom: 64px;
    color: ${uiKit.colors.experience.time};
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
`;

function Home() {
    const gradientRef = useRef(null);

    useEffect(() => {
        // Navigation system
        // Highlight the section on screen
        const aboutSection = document.getElementById('about'),
            experienceSection = document.getElementById('experience'),
            aboutLink = document.getElementById('aboutLink'),
            experienceLink = document.getElementById('experienceLink'),
            projectLink = document.getElementById('projectsLink');

        const navigation = () => {
            let aboutRect = aboutSection.getBoundingClientRect(),
                experienceRect = experienceSection.getBoundingClientRect(),
                sectionsOnScreen = {
                    about: aboutRect.top > -aboutRect.height,
                    experience: experienceRect.top > -experienceRect.height,
                };

            // If no about on screen we are on experience
            if (!sectionsOnScreen.about && sectionsOnScreen.experience) {
                aboutLink.classList.remove('active');
                experienceLink.classList.add('active');
                projectLink.classList.remove('active');
            }
            // If no about nor experience are on screen we are on the footer
            else if (!sectionsOnScreen.about && !sectionsOnScreen.experience) {
                aboutLink.classList.remove('active');
                experienceLink.classList.remove('active');
                projectLink.classList.add('active');
            }
            // Any other case we are on about
            else {
                aboutLink.classList.add('active');
                experienceLink.classList.remove('active');
                projectLink.classList.remove('active');
            }
        };
        navigation();

        // Gradient moving with the mouse on laptop
        const updateMousePosition = (evenement) => {
            if (!gradientRef.current) return;
            const { clientX, clientY } = evenement;
            gradientRef.current.style.setProperty('--x', `${clientX}px`);
            gradientRef.current.style.setProperty('--y', `${clientY}px`);
        };

        window.addEventListener('scroll', navigation);
        window.addEventListener('resize', navigation);
        window.addEventListener('mousemove', updateMousePosition);
        return () => {
            window.removeEventListener('scroll', navigation);
            window.removeEventListener('resize', navigation);
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);

    return (
        <MaxWidth>
            <HomeWrapper>
                <Gradient ref={gradientRef}></Gradient>
                <Header>
                    <IntroSection>
                        <Title>Axel Capaert</Title>
                        <SubTitle>Dev Front-End</SubTitle>
                        <IntroText>
                            {/* I build pixel-perfect, engaging, and accessible
                            digital experiences. */}
                            Je crée des expériences numériques au pixel près,
                            engageantes et accessibles.
                        </IntroText>
                        <Navigation>
                            <NavigationList>
                                <NavigationListItem>
                                    <NavigationLink
                                        href="#about"
                                        id="aboutLink"
                                    >
                                        <NavigationDecoration></NavigationDecoration>
                                        <NavigationText>
                                            à propos
                                        </NavigationText>
                                    </NavigationLink>
                                    <NavigationLink
                                        href="#experience"
                                        id="experienceLink"
                                    >
                                        <NavigationDecoration></NavigationDecoration>
                                        <NavigationText>
                                            expérience
                                        </NavigationText>
                                    </NavigationLink>
                                    <NavigationLink
                                        href="#projects"
                                        id="projectsLink"
                                    >
                                        <NavigationDecoration></NavigationDecoration>
                                        <NavigationText>projets</NavigationText>
                                    </NavigationLink>
                                </NavigationListItem>
                            </NavigationList>
                        </Navigation>
                    </IntroSection>
                    <Linkedin
                        href="https://be.linkedin.com/in/acapaert"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                        </svg>
                    </Linkedin>
                </Header>
                <RightWrapper>
                    <Main>
                        <Section id="about">
                            <SectionBanner>à propos</SectionBanner>
                            <p>
                                Passionné par la création digitale et le
                                développement web, je suis un développeur
                                chevronné avec 4 ans d'expérience dans le
                                domaine. Mon parcours professionnel m'a permis
                                de m'épanouir dans la conception et la
                                réalisation de solutions web innovantes.
                            </p>
                            <p>
                                Mon expertise technique s'étend des langages de
                                base tels que HTML, CSS, et JavaScript, PHP et
                                aux frameworks modernes tel que Laravel ou
                                WordPress. Fort de mon expérience, j'ai su
                                développer des compétences approfondies dans la
                                résolution de problèmes complexes, la conception
                                d'interfaces conviviales, et la gestion efficace
                                de projets du début à la fin.
                            </p>

                            <p>
                                Ce qui rend mon parcours unique, c'est
                                l'expérience enrichissante de 4 ans passés en
                                tant que barman. Ce passage dans l'univers de
                                l'hospitalité m'a permis de perfectionner mes
                                compétences interpersonnelles, d'apprendre à
                                gérer des situations stressantes et de
                                développer une capacité innée à comprendre et
                                répondre aux besoins variés de la clientèle.
                            </p>

                            <p>
                                Mon retour au développement web est motivé par
                                ma passion inaltérable pour cette discipline. Je
                                crois fermement que ma polyvalence, ma
                                créativité et ma capacité à m'adapter rapidement
                                font de moi un professionnel qui peut apporter
                                une perspective unique à votre équipe.
                            </p>

                            <p>
                                Je suis convaincu que mon parcours diversifié et
                                mes compétences techniques seront des atouts
                                pour contribuer au succès de votre entreprise.
                                Je suis impatient de discuter de la manière dont
                                je peux apporter ma passion et mon expérience à
                                votre équipe dynamique.
                            </p>
                        </Section>
                        <Section id="experience">
                            <SectionBanner>Expérience</SectionBanner>
                            <ol className="card-list">
                                {Experiences.map(
                                    ({
                                        time,
                                        title,
                                        description,
                                        tech,
                                        link,
                                    }) => (
                                        <div key={nanoid()}>
                                            <Card
                                                time={time}
                                                title={title}
                                                description={description}
                                                tech={tech}
                                                link={link}
                                            />
                                        </div>
                                    )
                                )}
                            </ol>
                            <SectionLink href={CV} target="_blank">
                                Voir CV complet
                                <SectionLinkArrow>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        viewBox="0 0 20 20"
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginLeft: '4px',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </SectionLinkArrow>
                            </SectionLink>
                        </Section>
                        <Section id="projects">
                            <SectionBanner>projets</SectionBanner>
                            <ol className="card-list">
                                {Projects.map(
                                    ({
                                        title,
                                        description,
                                        tech,
                                        img,
                                        link,
                                    }) => (
                                        <div key={nanoid()}>
                                            <Card
                                                title={title}
                                                description={description}
                                                tech={tech}
                                                img={img}
                                                link={link}
                                            />
                                        </div>
                                    )
                                )}
                            </ol>
                        </Section>
                    </Main>
                    <Footer>
                        <p>
                            Codé librement dans{' '}
                            <a
                                href="https://code.visualstudio.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="white"
                            >
                                Visual Studio Code
                            </a>{' '}
                            par mes soins. Construit avec{' '}
                            <a
                                href="https://fr.react.dev/"
                                target="_blank"
                                rel="noreferrer"
                                className="white"
                            >
                                React
                            </a>
                            , deployé sur{' '}
                            <a
                                href="https://www.hostinger.fr/"
                                target="_blank"
                                rel="noreferrer"
                                className="white"
                            >
                                Hostinger
                            </a>
                            . Tout le texte est défini dans la police{' '}
                            <a
                                href="https://fonts.google.com/specimen/Inter"
                                target="_blank"
                                rel="noreferrer"
                                className="white"
                            >
                                Inter
                            </a>
                            .
                        </p>
                    </Footer>
                </RightWrapper>
            </HomeWrapper>
        </MaxWidth>
    );
}

export default Home;
