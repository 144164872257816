import drytouch from '../../assets/drytouch-screenshot.jpg';
import kurikoni from '../../assets/kurikoni-screenshot.jpg';
import sorimo from '../../assets/sorimo-screenshot.jpg';
import theYHouse from '../../assets/the-y-house-screenshot.jpg';
import helpigo from '../../assets/helpigo-screenshot.jpg';

export const Projects = [
    {
        title: 'Dev Full Stack · Dry Touch',
        link: 'https://www.drytouch.be/',
        description: `Design (logo et charte graphique déjà existants), intégration, développement, déploiement en production d'un site OnePage pour un client qui a une société de traitement de l'humidité.`,
        tech: ['PHP', 'WordPress', 'SCSS', 'JavaScript', 'MySQL'],
        img: drytouch,
    },
    {
        title: 'Dev Full Stack · Kurikoni',
        link: 'https://www.kurikoni.com/',
        description: `Design, intégration, développement, déploiement en production d'un site vitrine pour une société de customisation de basses et guitares électriques.`,
        tech: ['PHP', 'WordPress', 'SCSS', 'JavaScript', 'MySQL'],
        img: kurikoni,
    },
    // {
    //     title: 'Dev Full Stack · The Y House',
    //     link: 'https://the-y-house.be/discovery-festival/',
    //     description: `Création, intégration, développement, mise en production du site web pour le Discovery Festival qu'organisait la boite de production "The Y House".`,
    //     tech: ['PHP', 'SilverStripe', 'CSS', 'JavaScript', 'MySQL'],
    //     img: theYHouse,
    // },
    {
        title: 'Dev Front-End · Sorimo',
        link: 'https://www.sorimo.be/',
        description: `Sous-traitance pour Career invest SPRL qui était chargée du projet de refonte du site web de Sorimo, je me suis chargé du design et de l'intégration du site à l'époque.`,
        tech: ['PHP', 'SCSS', 'JavaScript', 'MySQL'],
        img: sorimo,
    },
    {
        title: 'Dev Full Stack · Helpigo',
        link: 'https://www.header-com.be/fr/portfolio/medias-sociaux/helpigo',
        description:
            "(Startup qui a fait faillite) Développement du système d'offres d'emploi (recherche des offres correspondants aux profils, ou à l'inverse recherche des profils correspondants aux offres), d'alertes mail et implémentation du modèle freemium de la plateforme (intégration, back-end).",
        tech: ['PHP', 'Laravel', 'SCSS', 'JavaScript', 'MySQL'],
        img: helpigo,
    },
    {
        title: 'Dev Full Stack · FDistrict',
        link: '',
        description:
            'Chargé de toutes responsabilités au niveau de la représentation de la société FDistrict (immobilier) sur le web: Design, intégration, développement, mise en prod.',
        tech: ['PHP', 'WordPress', 'SCSS', 'JavaScript', 'MySQL'],
    },
];
