export const Experiences = [
    {
        time: 'mars 2024',
        title: 'Dev Full Stack · Portfolio',
        description: 'Design et développement de mon propre protfolio',
        tech: ['JavaScript', 'React', 'Styled Components'],
    },
    {
        time: 'jan - fév 2024',
        title: 'Dev Full Stack · Dry Touch',
        description: 'Design et développement du site',
        tech: ['PHP', 'JavaScript', 'WordPress', 'SCSS', 'MySQL'],
        link: 'https://www.drytouch.be',
    },
    {
        time: 'sept - nov 2023',
        title: 'Dev Full Stack · Kurikoni',
        description: 'Design et développement du site',
        tech: ['PHP', 'JavaScript', 'WordPress', 'SCSS', 'MySQL'],
        link: 'https://www.kurikoni.com',
    },
    {
        time: '2020 - fin 2023',
        title: 'Travail de nuit comme barman · Horeca',
        description: 'Service clientèle',
    },
    {
        time: '2017 - fin 2019',
        title: 'Différents projets · Indépendant',
        description:
            'Développement pour FDistrict, Helpigo, Sorimo, The Y House,... Voir section projets',
        tech: ['PHP', 'JavaScript', 'Zend', 'SCSS', 'jQuery', 'MySQL'],
    },
    {
        time: '2015 — Mars 2017',
        title: 'Dev Full Stack · Shelterr',
        description:
            'Intégration font-end, développement back-end, chronjob tasks, alertes mail, etc. Gestion des équipes de design, développement et stagiaires.',
        tech: ['PHP', 'JavaScript', 'Zend', 'SCSS', 'jQuery', 'MySQL'],
        link: 'https://www.rtl.be/art/info/magazine/hi-tech/shelterr-un-nouveau-site-immobilier-100-belge-qui-inverse-le-processus--707743.aspx',
    },
    {
        time: 'Fév — Sept 2015',
        title: 'Stage Dev Front-End · Shelterr',
        description:
            'Intégration, modification de pages statiques du site qui était alors à ses tout débuts suivi de travaux plus complets sur les pages principales du site immobilier.',
        tech: ['PHP', 'JavaScript', 'Zend', 'SCSS', 'jQuery', 'MySQL'],
        link: 'https://www.rtl.be/art/info/magazine/hi-tech/shelterr-un-nouveau-site-immobilier-100-belge-qui-inverse-le-processus--707743.aspx',
    },
];
