import { createGlobalStyle } from 'styled-components';
import uitKit from './uiKit';

const Transition = `
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
`;

const StyledGlobalStyle = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
        background-color: ${uitKit.colors.background};
        color: ${uitKit.colors.text};
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-variation-settings: "slnt" 0;
    }

    #about, #experience, #projects {
        scroll-margin: 96px;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    p {
        margin: 0;
        margin-bottom: 16px;
        line-height: 26px;
        color: ${uitKit.colors.greyText};
        &:last-child {
            margin-bottom: 0;
        }
        .white {
            color: ${uitKit.colors.text};
            font-weight: 500;
            text-decoration: none;
        }
    }

    ol, ul {
        list-style: none;
        margin: 0;
        padding: 0;

        &.card-list {
            &:hover {
                .card-grid {
                    @media screen and (min-width: ${uitKit.breakPoints.laptop}) {
                        ${Transition}
                        opacity: 0.5;
                    }
                    &:hover {
                        img {
                            ${Transition}
                            border-color: ${uitKit.colors.experience.imgHover};
                        }
                        @media screen and (min-width: ${uitKit.breakPoints.laptop}) {
                            opacity: 1 !important;
                            .card-background {
                                box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(148, 163, 184, 0.1) 0px 1px 0px 0px inset;
                                background-color: rgba(30, 41, 59, 0.5);
                            }
                        }
                    }
                }
            }
            .card-background {
                ${Transition}
                position: absolute;
                left: -24px;
                right: -24px;
                top: -16px;
                bottom: -16px;
                z-index: -1;
            }
        }
    }
`;

function GlobaStyle() {
    return <StyledGlobalStyle />;
}

export default GlobaStyle;
